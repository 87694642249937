import React from "react";

import base from "../../images/taxsheets/base.svg";
import top1 from "../../images/taxsheets/top1.svg";
import topExcel from "../../images/taxsheets/top-excel.svg";

import baseDashs from "../../images/taxdashs/base.svg";
import topDashs from "../../images/taxdashs/top.svg";

import logoTaxDashs from "../../images/taxdashs/Logo_TaxDashs.png";
import logoTaxSheets from "../../images/taxsheets/Logo_TaxSheets.png";

import * as S from "./style";
import Button from "../../DesignSystem/Button";
import { navigate } from "gatsby";

export default function ProductCard({ product, fluid, className }) {
  return (
    <S.Card className={className} $fluid={fluid} onClick={() => navigate(`/${product}`)}>
      {product === "taxsheets" ? (
        <>
          <div className="taxsheets-animate">
            <img src={base} alt="" className="base" />
            <img src={topExcel} alt="" className="topExcel" />
            <img src={top1} alt="" className="top1" />
          </div>
          <div>
            <img className="logo" src={logoTaxSheets} alt="Logo do TaxSheets" />
            <h3>Leitor e Editor de SPED</h3>
            <p>
              Transforme arquivos fiscais do SPED em planilhas editáveis
              do Excel e dê um salto na sua produtividade.
            </p>
            <Button size="big" fluid flat color="secondary">
              Conheça a solução
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="taxdashs-animate">
            <img src={baseDashs} alt="" className="baseDashs" />
            <img src={topDashs} alt="" className="topDashs" />
          </div>
          <div>
            <img className="logo" src={logoTaxDashs} alt="Logo do TaxDashs" />
            <h3>BI para Tax</h3>
            <p>
              Visualize as suas informações fiscais em relatórios interativos no
              Power BI e obtenha insights valiosos para sua empresa.
            </p>
            <Button size="big" fluid flat color="secondary">
              Conheça a solução
            </Button>
          </div>
        </>
      )}
    </S.Card>
  );
}
